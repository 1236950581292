import Vue from 'vue'
import Vuex from 'vuex'
import { MenuState } from '../api/model/index';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menu:<MenuState[]>[]
  },
  mutations: {
    setMenu(state,data:MenuState[]){
      console.log("+++++++++",data)
      state.menu = data
    }
  },
  actions: {
  },
  modules: {
  }
})
