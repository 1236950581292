

































import { MenuState } from "@/api/model";
import Vue from "vue";
import { PropType } from "vue/types/umd";
export default Vue.extend({
  name: "NavBarSub",
  props: {
    menuData: {
      type: Object as PropType<MenuState>,
      required: true,
    },
  },
  methods: {
    paramsParse(str: string) {
      var name = "",
        query: any = {};
      if (str.indexOf("?") == -1) {
        name = str;
        console.log({ name, query });
        return { name, query };
      } else {
        var s = str.split("?");
        name = s[0];
        let query = {};
        if (str.indexOf("?") != -1) {
          const s = str.split("?")[1]||"";
          const pairs = s.split("&");
          for (let i = 0; i < pairs.length; i++) {
            const pair = pairs[i].split("=");
            //  @ts-ignore
            query[pair[0]] = pair[1];
          }
        }
        return { name, query };
      }
    },
  },
});
