































































































































































































































































































































































































































































































import {
  artGroup,
  banner,
  carousel,
  getFinance,
  getFundList,
  proList,
  recommend,
} from "@/api/api";
import { Link, DonationDetail, ProItemDataState } from "@/api/model";
import card from "@/components/Page/card.vue";
import MImg from "@/components/Page/MImg.vue";
import VueSeamlessScroll from "vue-seamless-scroll";
import Vue from "vue";
export default Vue.extend({
  components: { card, MImg, VueSeamlessScroll },
  data() {
    return {
      activeName: "first", //新闻动态默认选项
      imgShow: true,
      // 请求返回参数
      notice: <Link>{}, //导航栏下方的醒目标题
      banner: <Link[]>[], //轮播图
      news: <Link[]>[], //轮播图旁边的总会要闻
      newsCity: <Link[]>[], //轮播图旁边的区县动态
      importNews: <Link[]>[], //重要公告
      recommend: <ProItemDataState>{}, //推荐项目
      donation: <Array<DonationDetail>>[], //捐赠明细
      publicity: <Link[]>[], //爱心公示
      charty: <ProItemDataState[]>[], //慈善项目
      zxjj: <ProItemDataState[]>[], //专项基金
      gmjj: <ProItemDataState[]>[], //冠名基金
      gyjj: <ProItemDataState[]>[], //公益基金
      art: <Link[]>[], //网站建设
      partyleads: <Link[]>[], //党建引领
      newsTop: <Link>{}, //头条
      smpleNews: <Link[]>[], //简报
      ad: <Link[]>[],
      donPage: 1,
      optionSetting: {
        step: 0.15, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      // 广告图
      banner().then((res) => {
        this.ad = res.flat(Infinity);
        console.log(this.ad);
      });
      // 轮播
      carousel().then((res) => {
        this.banner = res;
      });
      // 热门
      recommend().then((res) => {
        this.recommend = res.recommend_project;
        this.newsTop = res.recommend_article;
      });
      this.getDonation();
      // 慈善项目
      proList(1, 4).then((res) => {
        this.charty = res.list;
      });
      // 专项基金项目
      getFundList(7, 1, 4).then((res) => {
        this.zxjj = res.list;
      });
      // 冠名基金
      getFundList(8, 1, 4).then((res) => {
        this.gmjj = res.list;
      });
      // 公益基金
      getFundList(9, 1, 4).then((res) => {
        this.gyjj = res.list;
      });
      // 文章分组
      artGroup().then((res) => {
        res.map((v) => {
          switch (v.id) {
            case 1:
              // 要闻
              this.news = v.article_list;
              break;
            case 2:
              // 区县动态
              this.newsCity = v.article_list;
              break;
            case 3:
              // 重要公告
              this.importNews = v.article_list;
              break;
            case 14:
              // 爱心公示
              this.publicity = v.article_list;
              break;
            case 19:
              // 简报
              this.smpleNews = v.article_list;
              break;
            case 21:
              // 党建
              this.partyleads = v.article_list.splice(0, 4);
              break;
            case 9: //政策法规
            case 15: //志愿者风采
            case 5: //求助指南
            case 16: //慈善文化
            case 17: //工作动态
            case 18: //新闻资讯
              if (v.article_list.length > 0) {
                v.article_list[0].classity_name = v.name;
                this.art.push(v.article_list[0]);
              }
              break;
          }
        });
      });
      
    },
    nav(item: Link) {
      if (item.path) {
        this.$router.push({ path: item.path });
      } else if (item.type == "article") {
        this.$router.push({ name: "", query: { id: `${item.id}` } });
      } else if (item.type == "tab") {
        this.$router.push({ name: "", query: { id: `${item.id}` } });
      } else if (item.type == "list") {
        this.$router.push({ name: "", query: { id: `${item.id}` } });
      }
    },
    navArtDetail(item: Link) {
      this.$router.push({ name: "article", query: { id: `${item.id}` } });
    },
    navArtList(item: Link) {
      this.$router.push({
        name: "article_list",
        query: { type_id: `${item.classify_id}` },
      });
    },
    proDetail(item: ProItemDataState) {
      if (item.id > 0) {
        this.$router.push({ name: "pro_detail", query: { id: `${item.id}` } });
      }
    },
    adNav(item: Link) {
      if (item.url) {
        window.location.href = item.url;
      } else {
      }
    },
    getDonation() {
      getFinance(this.donPage, 100).then((res) => {
        this.donation.push(...res.list);
        this.donPage++;
      });
    },
  },
});
