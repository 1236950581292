








































































import Vue from "vue";
import { getFriendLink, tabNav } from "./api/api";
import { Link, MenuState } from "./api/model/index";
import NavBar from "./components/NavBar/NavBar.vue";
import md5 from "js-md5";
import MImg from "./components/Page/MImg.vue";
export default Vue.extend({
  components: { NavBar, MImg },
  data() {
    return {
      menuData: <Array<MenuState>>[],
      friend_link: <Array<Link>>[],
    };
  },
  created() {
    tabNav().then((res) => {
      this.$store.commit("setMenu", res);
      this.menuData = res;
    });
    getFriendLink().then((res) => {
      this.friend_link = res.friend_link;
    });
  },
  methods: {
    uuid() {
      var path = this.$route.fullPath;
      var t = new Date().getUTCMilliseconds();
      var r = Math.ceil(Math.random() + 10000);
      return md5(path + t + r);
    },
    nav(item: Link) {
      window.location.href = item.url || "";
    },
  },
});
