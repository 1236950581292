import '../theme/index.css'
import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.use(ElementUI);
Vue.config.productionTip = false
var urlWithDomain = function(url:string){
  if(!url){
      return "http://www.kmscszh.com/uploads/images/ee30435f9073f354e5e5357a03c9732f.png"
  }
  if(url.indexOf("http://")!=-1||url.indexOf("https://")!=-1){
      return url
  }else{
      return "http://www.kmscszh.com/uploads/"+url
  }
}
Vue.prototype.$urlWithDomain = urlWithDomain
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
