















import Vue from "vue";
export default Vue.extend({
  name: "card",
  props: {
    showBoder: { type: Boolean, default: false },
    borderColor: { type: String, default: "#f0f0f0" },
  },
});
