
















































import { MenuState } from "@/api/model";
import Vue from "vue";
import { PropType } from "vue/types/umd";
import NavBarSub from "./NavBarSub.vue";
export default Vue.extend({
  components: { NavBarSub },
  props: {
    menus: {
      type: Array as PropType<Array<MenuState>>,
      required: true,
    },
  },
  data() {
    return {
      active: "",
      isPhone: false,
      drawer: false,
    };
  },
  created() {
    this.isPhone =
      navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      ) != null;
    this.menus &&
      (() => {
        this.active = this.menus[0].id + "-m";
      });
  },
  methods: {
    handleSelect(key: any, path: any) {
      console.log(key, path);
      this.active = key;
    },
  },
});
