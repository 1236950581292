














import Vue from "vue";
import "element-ui/lib/theme-chalk/base.css";
// @ts-ignore
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import { PropType } from 'vue/types/umd';
Vue.component(CollapseTransition.name, CollapseTransition);
export default Vue.extend({
  props: {
    src: {
      type: String,
    },
    previewSrcList:{
      type:Array as PropType<string[]>
    }
  },
  data() {
    return {
      show: true,
      ans: [
        "el-zoom-in-center",
        "el-collapse-transition",
        "el-zoom-in-top",
        "el-zoom-in-bottom",
        "el-collapse-transition",
      ],
      an: "",
    };
  },
  created() {
    var s = parseInt(Math.random() * 100 + "") % 5;
    this.an = this.ans[s];
  },
  methods: {
    imgLoad() {
      var s = parseInt(Math.random() * 100 + "") % 5;
      this.an = this.ans[s];
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 20);
    },
  },
});
