import request from "@/func/request";
import { Dictionary } from "vue-router/types/router";
import { CommitDataState, DonationDetail, Information, Link, MenuState, ProItemDataState, ProTypeState, TimeLine, ArtGroup } from './model/index';

// 官网首页
// notice: <Link>{}, //导航栏下方的醒目标题
// banner: <Link[]>{}, //轮播图
// news: <Link[]>[], //轮播图旁边的总会要闻
// newsCity: <Link[]>[], //轮播图旁边的区县动态
// importNews: <Link[]>[], //重要公告
// recommend: <Link>{}, //推荐项目
// donation: <Array<DonationDetail>>[], //捐赠明细
// publicity: <Link[]>[], //爱心公示
// charty: <ProItemDataState[]>[], //慈善项目
// zxjj: <ProItemDataState[]>[], //专项基金
// gmjj: <ProItemDataState[]>[], //冠名基金
// art: <Link[]>[], //网站建设
export const index = (): Promise<{ notice: Link, banner: Link[], news: Link[], newsCity: Link[], importNews: Link[], recommend: Link, donation: DonationDetail[], publicity: Link[], charty: ProItemDataState[], zxjj: ProItemDataState[], gmjj: ProItemDataState[], art: Link[] }> => {
  return new Promise((ok, err) => {
    request({
      url: '/pc/index',
      method: 'post'
    }).then((res) => {
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 导航列表
export const tabNav = (): Promise<Array<MenuState>> => {
  return new Promise((ok, err) => {
    request({
      url: 'index/nav',
      method: 'get'
    }).then((res) => {
      
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 广告位
export const banner = (): Promise<Array<any>> => {
  return new Promise((ok, err) => {
    request({
      url: 'index/banner',
      method: 'get'
    }).then((res) => {
      
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 轮播图
export const carousel = (): Promise<Array<Link>> => {
  return new Promise((ok, err) => {
    request({
      url: 'index/carousel',
      method: 'get'
    }).then((res) => {
      
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 文章分组
export const artGroup = (): Promise<Array<ArtGroup>> => {
  return new Promise((ok, err) => {
    request({
      url: '/index/article/group-list',
      method: 'get'
    }).then((res) => {
      
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 推荐项目和头条
export const recommend = (): Promise<{recommend_project:ProItemDataState,recommend_article:Link}> => {
  return new Promise((ok, err) => {
    request({
      url: '/index/recommend',
      method: 'get'
    }).then((res) => {
      
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 慈善项目
export const proList = (page=1,page_size=10): Promise<{list:Array<ProItemDataState>,total:number}> => {
  return new Promise((ok, err) => {
    request({
      url: `/charity?page=${page}&page_size=${page_size}`,
      method: 'get'
    }).then((res) => {
      
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 基金项目
export const fundList = (page=1,page_size=10): Promise<{list:Array<ProItemDataState>,total:number}> => {
  return new Promise((ok, err) => {
    request({
      url: `/fund?page=${page}&page_size=${page_size}`,
      method: 'get'
    }).then((res) => {
      
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 项目详情
export const getProDetail = (type:number,id:number): Promise<ProItemDataState> => {
  return new Promise((ok, err) => {
    request({
      url: type==1?`charity/${id}`:`fund/${id}`,
      method: 'get',
    }).then((res) => {
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};


// 项目分类
export const getProType = (type:number): Promise<{tabs:ProTypeState[]}> => {
  return new Promise((ok, err) => {
    request({
      url: `/charity/list/${type}`,
      method: 'get'
    }).then((res) => {
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 财务
export const getFinance = (page:number,page_size=10): Promise<{list:DonationDetail[],total:number}> => {
  return new Promise((ok, err) => {
    request({
      url: `finance/list?page=${page}&page_size=${page_size}&money_start=1&money_end=2000`,
      method: 'get',
    }).then((res) => {
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 项目捐赠明细
export const getProPayRecord = (id:number,page:number): Promise<{list:DonationDetail[],total:number}> => {
  return new Promise((ok, err) => {
    request({
      url: `finance/project/${id}?page=${page}&page_size=10`,
      method: 'get',
    }).then((res) => {
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};


// 项目评论
export const getProCommit = (id:number,page:number): Promise<{list:CommitDataState[],total:number}> => {
  return new Promise((ok, err) => {
    request({
      url: `comment/project/${id}?page=${page}&page_size=10`,
      method: 'get'
    }).then((res) => {
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 项目资讯
export const getProInfor = (types:number,id:number,page:number): Promise<{list:Information[],total:number}> => {
  return new Promise((ok, err) => {
    request({
      url: `${types==1?'charity':'fund'}/${id}/article?page=${page}&page_size=10`,
      method: 'get'
    }).then((res) => {
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};

// 项目文章详情
export const getArticle = (id:number): Promise<Link> => {
  return new Promise((ok, err) => {
    request({
      url: `/article/${id}`,
      method: 'get',
    }).then((res) => {
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 基金项目列表
export const getFundList = (type_id:number,page:number,page_size=10): Promise<{list:Array<ProItemDataState>,total:number}> => {
  return new Promise((ok, err) => {
    request({
      url: `/fund?classify_id=${type_id}&page=${page}&page_size=${page_size}`,
      method: 'get',
    }).then((res) => {
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 基金项目列表
export const getCharityList = (type_id:number,page:number,page_size=10): Promise<{list:Array<ProItemDataState>,total:number}> => {
  return new Promise((ok, err) => {
    request({
      url: `/charity?classify_id=${type_id}&page=${page}&page_size=${page_size}`,
      method: 'get',
    }).then((res) => {
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 文章列表
export const getArticleList = (classify_id:string,page:number): Promise<{list:Array<Link>,total:number}> => {
  return new Promise((ok, err) => {
    request({
      url: `/index/article?page=${page}&page_size=10&classify_id=${classify_id}`,
      method: 'get',
    }).then((res) => {
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};
// 友情链接
export const getFriendLink = (): Promise<{friend_link:Array<Link>}> => {
  return new Promise((ok, err) => {
    request({
      url: `/index/summary`,
      method: 'get',
    }).then((res) => {
      ok(res.data);
    }).catch(e => {
      err(e);
    });
  });
};